import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {LabelModel} from '../models/label.model';
import {BehaviorSubject} from 'rxjs';
import {LabelTypeEnum} from '../enums/labelType.enum';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {
  public automaticLabels: any = new BehaviorSubject<LabelModel[]>(null);
  constructor(
    private apiSvc: ApiService
  ) {
  }


  public async getAllLabels(): Promise<LabelModel[]> {
    const labels = await this.apiSvc.send<LabelModel[]>('GET', 'label', null, false);
    console.warn("LABELS: ", labels)
    this.automaticLabels.next(labels.filter(l => l.type === LabelTypeEnum.AUTOMATIC));
    return labels;
  }

  public getAutomaticLabel(itemValidFrom: string, itemValidTo: string): any {
    const now = moment();
    const labelsActive: LabelModel[] = this.automaticLabels.value.filter((l: LabelModel) => l.fromCreated || l.fromEnd);
    const labels: LabelModel[] = [];
    const fromItemCreated = now.diff(moment(moment(itemValidFrom, 'DD.MM.YYYY')), 'days');
    const toItemEnd = moment(moment(itemValidTo, 'DD.MM.YYYY')).add(1, 'day').diff(moment(), 'days');
    if (labelsActive.length) {
      for (const label of labelsActive) {
        if ((label.fromCreated !== null && label.fromCreated > -1) && !label.fromEnd) {
          if (fromItemCreated <= label.fromCreated) {
            labels.push(label);
          }
        }
        if (!label.fromCreated && (label.fromEnd !== null && label.fromEnd > -1)) {

          if (toItemEnd <= label.fromEnd) {
            labels.push(label);
          }
        }
        if ((label.fromCreated !== null && label.fromCreated > -1) && (label.fromEnd !== null && label.fromEnd > -1)) {
          if (fromItemCreated <= label.fromCreated || toItemEnd <= label.fromEnd) {
            labels.push(label);
          }
        }
      }
    }
    return labels.length ? labels[labels.length - 1].name : '';
  }

}
