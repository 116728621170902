import {NgModule, ErrorHandler, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgHeroiconsModule} from '@dimaslz/ng-heroicons';
import {CommonModule, DecimalPipe, registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {LoadingHelper} from './helpers/loading.helper';
import {ShadowRootHelper} from './helpers/shadowRoot.helper';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {FunctionsHelper} from './helpers/functions.helper';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import * as Sentry from '@sentry/angular';
import {LaunchNavigator} from '@awesome-cordova-plugins/launch-navigator/ngx';
import {AccordionHelper} from './helpers/accordion.helper';
import {Observable, switchMap} from 'rxjs';
import {environment} from '../environments/environment';
import {MaskitoDirective} from "@maskito/angular";

registerLocaleData(localeCs, 'cs');

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {
  }

  // @ts-ignore
  public getTranslation(lang: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'languages').pipe(
      switchMap((languages: any) => this.http.get(environment.apiUrl + 'languages/' + languages.data.filter(lng => lng.code === lang)[0].id + '/translations')
      ));
  }
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    NgCircleProgressModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        //useFactory: (createTranslateLoader),
        useClass: CustomLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'cs'
    }),
    AppRoutingModule,
    NgHeroiconsModule,
    MaskitoDirective
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): any => {
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    LoadingHelper,
    ShadowRootHelper,
    FunctionsHelper,
    AccordionHelper,
    InAppBrowser,
    LaunchNavigator,
    DecimalPipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
