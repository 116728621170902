import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

// Init for the web
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled = true;

  constructor(private router: Router,
              private platform: Platform
  ) {
    //this.initFb();
    this.router.events.pipe(
      filter((e: any) => e instanceof NavigationEnd)
    ).subscribe((e: RouterEvent) => {
      console.log('route changed: ', e.url);
      this.setScreenName(e.url);
    });
  }

  async initFb() {
    if (this.platform.platforms().includes('capacitor')) {
      await FirebaseAnalytics.getAppInstanceId();
    } else {
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  setUser() {
    // Use Firebase Auth uid
    FirebaseAnalytics.setUserId({
      userId: 'test_123'
    });
  }

  setProperty() {
    FirebaseAnalytics.setUserProperty({
      name: 'framework',
      value: 'angular'
    });
  }

  logEvent() {
    FirebaseAnalytics.logEvent({
      name: 'login',
      params: {
        method: 'email'
      }
    });
  }

  async setScreenName(screenName) {
    await FirebaseAnalytics.setScreenName({
      screenName
    });
  }

  toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setCollectionEnabled({
      enabled: this.analyticsEnabled
    });
  }

}
