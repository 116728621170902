import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';
import { Http } from '@capacitor-community/http';
import { HttpParams } from '@capacitor-community/http/dist/esm/definitions';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class LinkedinService {
  constructor(
    private http: HttpClient
  ) {
  }

  getRandomState() {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 21; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getAccessToken(authCode) {
    const body = {
      code: authCode,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      grant_type: 'authorization_code',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: 'http://localhost/callback',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: environment.linkedinClientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_secret: environment.linkedinClientSecret
    };
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    const headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const options = {
      url: 'https://www.linkedin.com/oauth/v2/accessToken',
      data: body,
      headers
    };

    return Http.post(options);
  }


  async getName(accessToken) {
    const headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${accessToken}`
    };
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          url: 'https://api.linkedin.com/v2/me',
          headers
        };
        const resp = await Http.get(options);
        console.log('GET ME: ', resp);
        const firstName = resp.data.localizedFirstName;
        const lastName = resp.data.localizedLastName;
        resolve(firstName + ' ' + lastName);
      } catch (e) {
        Sentry.captureException(JSON.stringify(e));
        console.error(e);
        reject('Error. Couldn\'t fetch name');
      }
    });
  }

  getProfilePic(accessToken) {
    const headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${accessToken}`
    };

    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          url: 'https://api.linkedin.com/v2/me?projection=(id,profilePicture(displayImage~:playableStreams))',
          headers
        };
        const resp = await Http.get(options);
        console.log('GET ME: ', resp);
        resolve(
          resp.data.profilePicture['displayImage~'].elements[0]
            .identifiers[0].identifier
        );
      } catch (e) {
        Sentry.captureException(JSON.stringify(e));
        console.error(e);
        reject('Error getting profile pic');
      }
    });

  }

  getEmail(accessToken) {
    const headers = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${accessToken}`
    };
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          url: 'https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))',
          headers
        };
        const resp = await Http.get(options);
        console.log('GET ME: ', resp);
        resolve(resp.data.elements[0]['handle~'].emailAddress);

      } catch (e) {
        Sentry.captureException(JSON.stringify(e));
        console.error(e);
        reject(e);
      }
    });
  }
}
