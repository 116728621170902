import {Injectable} from '@angular/core';
import {ActionSheetController, NavController, Platform} from '@ionic/angular';
import {LaunchNavigator, LaunchNavigatorOptions} from '@awesome-cordova-plugins/launch-navigator/ngx';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class FunctionsHelper {

  public constructor(
    private actionSheetCtrl: ActionSheetController,
    private launchNavigator: LaunchNavigator,
    private platform: Platform,
    private translateSvc: TranslateService,
    private navCtrl: NavController
  ) {
  }

  goBack() {
    this.navCtrl.back({animationDirection: 'back'});
  }

  public slugify(text: string): string {
    return text
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  decodeBase64(base64: string): any {
    return atob(base64);
  }

  sort(array: any, sortBy: any): any {
    return array.sort((a, b) => b[sortBy] - a[sortBy]);
  }

  async presentNavigateSheet(lat: string, lng: string) {
    console.log(lat, lng);
    const buttons: any = [{
      text: '',
      icon: 'close',
      cssClass: 'close',
      handler: () => {
        console.log('Cancel clicked');
      }
    }];

    const options = {
      app: ''
    };

    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.HERE_MAPS)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.HERE_MAPS),
        handler: async () => {
          options.app = this.launchNavigator.APP.HERE_MAPS;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.SYGIC)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.SYGIC),
        handler: async () => {
          options.app = this.launchNavigator.APP.SYGIC;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.WAZE)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.WAZE),
        handler: async () => {
          options.app = this.launchNavigator.APP.WAZE;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.GOOGLE_MAPS)) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.GOOGLE_MAPS),
        handler: async () => {
          options.app = this.launchNavigator.APP.GOOGLE_MAPS;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);

        }
      });
    }
    if (await this.launchNavigator.isAppAvailable(this.launchNavigator.APP.APPLE_MAPS) && this.platform.is('ios')) {
      buttons.unshift({
        text: this.launchNavigator.getAppDisplayName(this.launchNavigator.APP.APPLE_MAPS),
        handler: async () => {
          options.app = this.launchNavigator.APP.APPLE_MAPS;
          await this.launchNavigator.navigate(lat + ', ' + lng, options);
        }
      });
    }
    console.log(await this.launchNavigator.availableApps());
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateSvc.instant('helper.lunch-navigator.header'),
      cssClass: 'apps-action-sheet',
      mode: 'md',
      buttons
    });
    await actionSheet.present();
  }

}

