import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ToastController } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private authSvc: AuthService,
    private router: Router,
    private apiSvc: ApiService,
    private toastCtrl: ToastController,
    private translateSvc: TranslateService
  ) {
  }

  async canActivate(): Promise<boolean> {
    if (this.apiSvc.isLogged && this.apiSvc.isValid && this.authSvc.profile && !this.authSvc.profile.verified) {
    /*  const toast = await this.toastCtrl.create({
        message: this.translateSvc.instant('guard.auth.toast.message'),
        duration: 3000,
        position: 'bottom',
        cssClass: 'toast--danger'
      });
      await toast.present();*/
      await this.router.navigateByUrl('/auth/activation');
      return false;
    }
    if (!this.apiSvc.isLogged) {
     /* const toast = await this.toastCtrl.create({
        message: 'Pro přístup do aplikace se musíte přihlásit.',
        duration: 3000,
        position: 'bottom',
        cssClass: 'toast--warning'
      });
      await toast.present();*/
      await this.router.navigateByUrl('/auth/sign-in');
      return false;
    }
    return this.apiSvc.isLogged && this.apiSvc.isValid && (this.authSvc.profile && this.authSvc.profile.verified);
  }
}
