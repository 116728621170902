import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';
import {LanguageModel} from "../models/language.model";
import {TranslateService} from "@ngx-translate/core";
import {Device} from "@capacitor/device";


@Injectable({
  providedIn: 'root'
})
export class TranslatesService {

  public languages: any = new BehaviorSubject<Array<LanguageModel>>(null);
  public sharedLanguages: any = this.languages.asObservable();

  public currentLanguage: any = new BehaviorSubject<any>(null);
  public sharedCurrentLanguage: any = this.currentLanguage.asObservable();

  public translates: any = new BehaviorSubject<Array<any>>(null);
  public sharedTranslates: any = this.translates.asObservable();


  constructor(
    private apiSvc: ApiService,
    private translateSvc: TranslateService
  ) {

  }

  public async init() {
    let lang: string;
    if (localStorage.getItem('VIP_language')) {
      lang = localStorage.getItem('VIP_language');
      if(localStorage.getItem('VIP_language').includes('-')){
        lang = localStorage.getItem('VIP_language').split('-')[0]
      }
    } else {
      lang = (await Device.getLanguageCode()).value
    }
    const bodyTag: HTMLElement = document.body;
    bodyTag.classList.add(lang);

    this.translateSvc.use(lang);
    this.currentLanguage.next(lang);
    await this.getLanguages();
  }

  public async getTranslations(): Promise<any> {
    return await this.apiSvc.send<any>('GET', 'translations');
    //return this.documents.next(resp);
  }

  public async getTranslationsByLng(lngCode: string = 'cs'): Promise<any> {
    const lngId: number = this.languages.value.filter((lng) => lng.code === lngCode)[0].id;

    return await this.apiSvc.send<any>('GET', 'languages/' + lngId + '/translations', null, false);
    //return this.documents.next(resp);
  }

  public async createTranslations(): Promise<any> {
    return await this.apiSvc.send<any>('POST', 'translations', {
      key: 'hello_world'
    });
    //return this.documents.next(resp);
  }

  public async createBulkTranslations(id: number, body: any): Promise<any> {
    return await this.apiSvc.send<any>('POST', 'translations/' + id + '/values/bulk', body);
    //return this.documents.next(resp);
  }

  public async getLanguages(): Promise<{ data: Array<LanguageModel> }> {
    const resp: { data: Array<LanguageModel> } = await this.apiSvc.send<{
      data: Array<LanguageModel>;
    }>('GET', 'languages', null, false);
    this.languages.next(resp.data);
    return resp;
  }

  public async getLanguageById(id: number): Promise<LanguageModel> {
    return await this.apiSvc.send<LanguageModel>('GET', 'languages/' + id, null, false);
  }

  public getCurrentLanguage(): string {
    return this.currentLanguage.value;
  }

  public async switchLanguage(newLng: string): Promise<string> {
    await this.getTranslationsByLng(newLng);
    localStorage.setItem('VIP_language', newLng);
    const bodyTag: HTMLElement = document.body;
    if (bodyTag.classList.contains(this.getCurrentLanguage())) {
      bodyTag.classList.remove(this.getCurrentLanguage());
    }
    bodyTag.classList.add(newLng);
    this.translateSvc.use(newLng);
    this.currentLanguage.next(newLng);
    return this.currentLanguage.next(newLng);
  }


}
