import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class LoadingHelper {
  public loading: HTMLIonLoadingElement;
  private alertTitle = this.translateSvc.instant('helper.loader.title');
  private alertMessage = this.translateSvc.instant('helper.loader.message');


  public constructor(
    private loadingCtrl: LoadingController,
    private translateSvc: TranslateService

  ) {
  }

  public async presentLoader(title: string = this.alertTitle, message: string = this.alertMessage, place = null): Promise<any> {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
    this.loading = await this.loadingCtrl.create({
      cssClass: 'my-custom-class',
      spinner: 'circular',
      duration: 3000
    });
    console.log('====  LOADER PRESENTED ON: ' + place + '  ====');
    await this.loading.present();

  }

  public async dismissLoader(place = null): Promise<any> {
    console.log('====  LOADER DISMISSED ON: ' + place + '  ====');
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

}
