// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--ion-color-light);
  z-index: 99999;
  transition: all 1s ease;
}
.content-inner.animate {
  opacity: 0;
  z-index: -1;
}

.logo-wrapper {
  position: relative;
  z-index: 1;
  width: 100px;
  margin: 0 auto;
}
.logo-wrapper img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kCAAA;EACA,cAAA;EACA,uBAAA;AAAF;AAEE;EACE,UAAA;EACA,WAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,cAAA;AADF;AAGE;EACE,WAAA;AADJ","sourcesContent":["\n.content-inner {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  background: var(--ion-color-light);\n  z-index: 99999;\n  transition: all 1s ease;\n\n  &.animate {\n    opacity: 0;\n    z-index: -1;\n  }\n}\n\n.logo-wrapper {\n  position: relative;\n  z-index: 1;\n  width: 100px;\n  margin: 0 auto;\n\n  img {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
