import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FilterService {

  public selectedFilters: any = new BehaviorSubject<Array<any>>(null);
  public sharedSelectedFilters: any = this.selectedFilters.asObservable();


  constructor(
    private apiSvc: ApiService
  ) {

  }

  public async getFilters(): Promise<any> {
    return await this.apiSvc.send<any>('GET', 'offer/filters', null, false);
  }

  public getSelectedFilters(): any {
    if (!this.selectedFilters.value) {
      this.selectedFilters.next(this.getStoredSelectedFilters());
    }
    return this.selectedFilters.value;
  }

  public saveSelectedFilters(data: any): void {
    localStorage.setItem('VIP-selectedFilters', JSON.stringify(data));
  }

  public clearStoredFilters(): void {
    localStorage.removeItem('VIP-selectedFilters');
  }

  public async getFilterForApi(data = null, alreadySorted: boolean = false): Promise<any> {
    let selectedFilters = [];
    if (alreadySorted) {
      selectedFilters = data;
    } else {
      for (const filter in data) {
        if (data[filter] && data[filter].length) {
          selectedFilters.push({
            key: filter,
            value: data[filter]
          });
        }
      }

    }
    this.selectedFilters.next(selectedFilters);
    this.saveSelectedFilters(selectedFilters);
    return selectedFilters;
  }

  private getStoredSelectedFilters() {
    if (localStorage.getItem('VIP-selectedFilters')) {
      return JSON.parse(localStorage.getItem('VIP-selectedFilters'));
    }
  }


}
